.App {
  text-align: center;
  color: black;
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
}

.App-header {
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.announcement-banner {
  color: darkred;
  font-size: 18px;
}

.contact-info {
  font-size: 18px;
  color: black;
}

.App-content {
  display: flex;
  justify-content: space-evenly;
  margin-top: 18px;
}

.store-info-header {
  margin-bottom: 0px;
  margin-top: 12px;
  text-align: left;
}

.store-info-content {
  list-style-type: none;
  color: black;
  text-align: left;
  margin-top: 0px;
  padding-top: 0px;
}

.information-container {
  border-style: solid;
  border-width: 2px;
  border-radius: 24px;
  border-color: black;
  /* min-width: 200px; */
  max-width: 800px;
  padding: 18px;
  display: flex;
  justify-content: space-evenly;
  font-size: 24px;
  flex-wrap: wrap;
  margin-left: 12px;
  margin-right: 12px;
}

.emphasized-text {
  font-weight: bold;
}
.links-header {
  text-align: left;
  margin-top: 12px;
  padding-top: 0px;
  margin-left: 40px;
  margin-bottom: 0px;
}

.links-content {
  margin-top: 0px;
  padding-top: 0px;
  align-content: leftt;
  li {
    list-style-type: none;
    color: black;
    text-align: left;
  }
}

button {
  transform: scale(1.5);
  background-color: light-grey;
  box-shadow: 0 8px 16px 0 rgba(41, 41, 41, 0.2);
  margin-top: 36px;
  margin-bottom: 36px;
}

.buying-info-container {
  margin-top: 36px;
  font-size: 18px;
  p {
    text-align: left;
  }
}

.inventory-link {
  margin-top: 18px;
}

.media-types {
    width: 100%;
    height: auto;
    max-width:600px;
}

/* .buying-qa {
    max-height: 800px;
    overflow-y: scroll;
    scrollbar-width: thin;
    padding: 18px;
} */

.buying-header {
  font-size: 24px;
  margin-top: 48px;
  margin-bottom: 0px;
}

.buying-info {
  font-size: 16px;
}

.buying-qa {
margin: auto;
  max-width: 66%;
}